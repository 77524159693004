<template>
  <div class="background">
    <div class="left_box">
      <div class="loginForm">
        
        <div class="form">
          <div class="passwordLoginForm">
            <p class="title">朗云欢迎您</p>
            <p class="secTitle">通过填写表格登陆您的账户</p>
            <div style="margin-bottom: .2rem">
              <el-checkbox-group v-model="checkList" :min="0" :max="1">
                <el-checkbox label="我有创意"  style="
                  --el-checkbox-checked-font-color: #ec414d;
                  --el-checkbox-checked-background-color: #ec414d;
                  --el-checkbox-input-border-color-hover: #ec414d"/>
                <el-checkbox label="我有需求"  style="
                  --el-checkbox-checked-font-color: #ec414d;
                  --el-checkbox-checked-background-color: #ec414d;
                  --el-checkbox-input-border-color-hover: #ec414d"/>
              </el-checkbox-group>
            </div>

            <div class="inputArea">
              <p>手机号/邮箱</p>
              <input type="text" v-model="registerForm.text">
              <p>密码</p>
              <div style="position:relative">
              <input type="password" v-model="registerForm.password">
              <div class="eye" @click="changeInputType">
              <i style="font-size:.28rem;color: #bebebe;margin-bottom: 1.35rem" class="fa">&#xf06e</i>
              </div>
              </div>
              <p>验证码</p>
              <div>
                <input type="text" v-model="registerForm.code" style=" height: .3rem;
                      width: 100%;
                      margin-bottom: .31rem;
                      border-bottom: .02rem solid #d4d4d4;
                      margin-top: .24rem;
                      float: left;">
                <p @click="checkCode"  style="float: right; color: #ec414d;margin-top: -0.72rem; margin-left: .2rem;cursor: pointer">发送验证码</p>
              </div>

              <div style="margin-top: -0.06rem">
                <el-checkbox-group v-model="rememberMe">
                  <el-checkbox label="我已阅读并同意《朗云云平台用户服务协议》" style="
                  --el-checkbox-checked-font-color: #ec414d;
                  --el-checkbox-checked-background-color: #ec414d;
                  --el-checkbox-input-border-color-hover: #ec414d"/>
                </el-checkbox-group>
              </div>
            </div>
            <div class="signup">
              <p>已有账号？</p>
              <p @click="this.$router.push('/Login')">立即登陆</p>
              <button @click="register">注册</button>
            </div>
          </div>
          <div class="codeLogin"></div>
        </div>
      </div>
    </div>
    <div class="rightPic"></div>

  </div>
</template>

<script>
import { h, ref } from "vue";
import { ElDivider } from "element-plus";

export default {
  name: "Login",


  data() {
    return {
      spacer: h(ElDivider, { direction: "vertical" }),
      registerForm: {
        text: "",
        password: "",
        role: "designer",
        code: "",
      },
      checkList: ref([]),
      rememberMe: ref([]),
    };
  },
  methods: {
    checkCode() {
      this.$http({
        method: "post",
        url: "/sendEmail",
        data: this.registerForm,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$notify({
            title: "验证码发送失败",
            message: res.data.message,
            type: "warning",
          });
        } else {
          this.$notify({
            title: "验证码发送成功",
            message: res.data.message,
            type: "success",
          });
        }
      });
    },
    register() {
      if (this.checkList[0] === "我有创意") {
        this.registerForm.role = "designer";
      } else if (this.checkList[0] === "我有需求") {
        this.registerForm.role = "enterprise";
      }
      console.log(this.registerForm);
      this.$http({
        method: "post",
        url: "/register",
        data: this.registerForm,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$notify({
            title: "注册失败",
            message: res.data.message,
            type: "warning",
          });
        } else {
          this.$notify({
            title: "注册成功",
            message: res.data.message,
            type: "success",
          });
          this.$router.push("/Login");
        }
      });
    },
    changeInputType() {
      if (this.inputPsdType === "password") {
        this.inputPsdType = "text";
      } else {
        this.inputPsdType = "password";
      }
    },
  },
};
</script>

<style scoped>
.background {
  height: 9.34rem;
  width: 19.2rem;
}

.left_box {
  position: absolute;
  width: 9.6rem;
  height: 9.34rem;
  background-image: url("../../images/login_bac1.jpg");
  background-size: 100% 100%;
}

.loginForm {
  position: absolute;
  top: 1.44rem;
  left: 1.8rem;
  width: 6.56rem;
  height: 7.5rem;
  box-shadow: #d4d2d2 0 0.15rem 0.2rem;
  -webkit-box-shadow: #d4d2d2 0 0 0.2rem;
  -moz-box-shadow: #d4d2d2 0 0 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.51rem;
  padding-top: 0.55rem;
  background-color: #ffffff;
  border-radius: 0.1rem;
}

.loginForm .eye {
  position: absolute;
  right: 0rem;
  bottom: 1.62rem;
  cursor: pointer;
  top: 20px;
}

.loginForm .formTitle {
  width: 5.56rem;
  height: 0.52rem;
  margin: 0 auto;
  border-bottom: 0.01rem solid #979797;
  padding-left: 0.1rem;
}

.loginForm .formTitle div {
  width: 1.74rem;
  height: 0.52rem;
  float: left;
  text-align: center;
  font-size: 0.24rem;
  color: #212121;
  font-weight: 500;
}

.loginForm .formTitle .passwordLoginForm {
  width: 1.8rem;
}

.loginForm .formTitle .passwordLoginForm p {
  float: left;
  width: 1.79rem;
  height: 0.52rem;
  margin-bottom: 0.26rem;
}

.loginForm .formTitle .passwordLoginForm i {
  display: block;
  float: left;
  width: 0.01rem;
  height: 0.3rem;
  background-color: #cccccc;
}

.loginForm .formTitle .passwordLogin p {
  float: left;
  width: 1.73rem;
  height: 0.52rem;
  margin-bottom: 0.26rem;
}

.loginForm .formTitle .passwordLogin i {
  display: block;
  float: left;
  width: 0.01rem;
  height: 0.3rem;
  background-color: #cccccc;
}

.loginForm .formTitle .codeLogin {
  margin-bottom: 0.26rem;
  /*margin-left: .45rem;*/
  padding-left: 0.2rem;
}

.loginForm .form {
  font-size: 0.21rem;
  font-weight: 350;
  color: rgba(60, 60, 60, 0.55);
}

.loginForm .form .passwordLoginForm .title {
  font-size: 0.36rem;
  font-weight: 300;
  color: #212121;
  /*margin-top: .5rem;*/
}

.loginForm .form .passwordLoginForm .secTitle {
  font-size: 0.18rem;
  font-weight: 300;
  margin-top: 0.22rem;
  margin-bottom: 0.28rem;
  color: #3c3c3c;
}

.form .passwordLoginForm .input_area p {
  height: 0.21rem;
}

.form .passwordLoginForm input {
  /*background-color: black;*/
  height: 0.3rem;
  width: 100%;
  margin-bottom: 0.31rem;
  border-bottom: 0.02rem solid #d4d4d4;
  margin-top: 0.24rem;
}

.form .passwordLoginForm .signup {
  float: left;
  width: 100%;
  margin-top: 0.38rem;
  font-size: 0.18rem;
  font-weight: 400;
}

.form .passwordLoginForm .signup p {
  float: left;
  width: 115px;
}

.form .passwordLoginForm .signup p:nth-child(2) {
  margin-left: 0.1rem;
  color: rgb(235, 66, 77);
  cursor: pointer;
}

.form .passwordLoginForm button {
  float: right;
  background-color: rgb(235, 66, 77);
  color: white;
  font-size: 0.21rem;
  width: 1.38rem;
  height: 0.48rem;
  line-height: 0.48rem;
  border-radius: 0.05rem;
  background-image: linear-gradient(115deg, transparent 50%, #212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}

.form .passwordLoginForm button:hover {
  background-size: 300%;
}

.form .passwordLoginForm .role {
  height: 0.2rem;
  /*background-color: pink;*/
  margin-bottom: 0.3rem;
}

.form .passwordLoginForm .role div {
  float: left;
  width: 0.2rem;
  height: 0.2rem;
  line-height: 0.2rem;
  border-radius: 50%;
}

.form .passwordLoginForm .role div:nth-of-type(1) {
  background-color: #ec414d;
}

.form .passwordLoginForm .role div:nth-of-type(2) {
  background-color: #212121;
}

.form .passwordLoginForm .role i {
  display: block;
  float: left;
  height: 0.2rem;
  line-height: 0.2rem;
  color: #212121;
}

.rightPic {
  position: absolute;
  right: 0;
  width: 9.6rem;
  height: 9.34rem;
  background-image: url("../../images/login_bac2.jpg");
  background-size: 100% 100%;
}

/*临时加急按钮css*/
.role input {
  margin-top: -0.05rem !important;
  border-radius: 50% !important;
  background-color: #e93d50 !important;
}
</style>
